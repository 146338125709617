import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import homeLogo from "../../Assets/home-main.svg";
import homeLogo from "../../Assets/home-safi.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import Contact from "./contact";
import { StarsCanvas } from "../canvas";

function Home() {
  return (
    <div>
      <div className="h-min-screen">
        <Container
          fluid
          className="home-section flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row"
          id="home"
        >
          {/* <Container className="home-content"> */}
          <h1 style={{ paddingBottom: 15 }} className="heading">
            <p>
              Yoo I'M
              <strong className="main-name"> Mohammad Safi</strong>
            </p>
            <div style={{ padding: 10, textAlign: "left", fontSize: 16 }}>
              <Type />
            </div>
          </h1>
          <img
            src={homeLogo}
            alt="Mohammad Safi in a dark purple buisness suit"
            className="img-fluid"
            style={{
              maxHeight: "450px",
              maxWidth: "450px",
              alignSelf: "center",
            }}
          />
          <Particle />
        </Container>
        <Container>
          <h2 className="mt-20 mb-10 text-center text-lg md:mx-20 md:text-2xl">
            <p>
              <strong className="main-name"> Mohammad Safi</strong>, Founder of
              Free Me VPN, Holding a Computer Science degree, Marketing Free
              Lancer, Passionate about Blockchain, Amateur Boxer from a young
              age.
            </p>
          </h2>
        </Container>
      </div>

      {/* </Container> */}
      <Home2 />
      <div className="relative z-0">
        <Contact />
        <StarsCanvas />
      </div>
      <StarsCanvas />
    </div>
  );
}

export default Home;
