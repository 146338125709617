import React from "react";
import { StarsCanvas } from "../canvas";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import "./portfolio.scss";
import safi1 from "../../Assets/safi/mohammad-safi-public-speaking.jpg";
import safi2 from "../../Assets/safi/mohammad-safi-in-interview.jpg";
import safi3 from "../../Assets/safi/mohammad-safi-with-professor.jpg";
import safi4 from "../../Assets/safi/mohammad-safi-innopolis-snow.jpg";
import safi5 from "../../Assets/safi/mohammad-safi-innopolis-university.jpg";
import safi6 from "../../Assets/safi/mohammad-safi-IU.jpg";
import safi7 from "../../Assets/safi/mohammad-safi-in-a-bar.png";
import safi8 from "../../Assets/safi/mohammad-saif-drinking-whisky.png";
import safi9 from "../../Assets/safi/mohammad-safi-in-gum-mall.png";
import safi10 from "../../Assets/safi/mohammad-safi-in-red-square.png";
import safi11 from "../../Assets/safi/mohammad-safi-standing-in-syria-streets.png";
import safi12 from "../../Assets/safi/mohammad-safi-boxing.png";

const items = [
  {
    id: 1,
    title: "Innopolis University",
    alt: "Mohammad Safi standing in snow in front of innopolis",
    img: safi4,
    desc: "I got accepted to study computer science at Innopolis University. I left Syria, and this is where everything started.",
  },
  {
    id: 2,
    title: "Studies and Work",
    alt: "Mohammad Safi in his university",
    img: safi6,
    desc: "I started working as a freelancer while I was studying to improve my skills and graduate with work experience.",
  },
  {
    id: 3,
    title: "Connecting and Networking",
    alt: "Mohammad Safi giving a presentation",
    img: safi1,
    desc: "Making new friends and finding new talented people was one of the most important steps in my university life.",
  },
  {
    id: 4,
    title: "Getting To Cyber Security",
    alt: "Mohammad Safi in an interview",
    img: safi2,
    desc: "In my second year, I chose getting into the cyber security track to complete my pation as a kid playing around with Kali Linux tools.",
  },

  {
    id: 5,
    title: "Learning from the Great people",
    alt: "Mohammad Safi with his professor",
    img: safi3,
    desc: "The fact that I was able to meet and learn from great people in the computer science industry was more than enough to push me to higher levels.",
  },
];

const Single = ({ item }) => {
  return (
    <section>
      <div className="container">
        <div className="wrapper">
          <div className="imageContainer">
            <img src={item.img} alt={item.alt} loading="lazy" />
          </div>
          <div className="textContainer">
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const FunTimes = () => {
  const instagramPosts = [
    {
      img: safi5,
      url: "https://www.instagram.com/mohmmed_safi/",
      alt: "Mohammad having a good time in Classy building",
    },
    {
      img: safi7,
      url: "https://www.instagram.com/p/C3AaeSOoa_T/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      alt: "Mohammad Safi sitting in a bar",
    },
    {
      img: safi8,
      url: "https://www.instagram.com/p/C06WOi_unEZ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      alt: "Mohammad Safi drinking in a library",
    },
    {
      img: safi9,
      url: "https://www.instagram.com/p/CiJoYfTOHYS/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      alt: "Mohammad Safi standing in GUM mall for shopping",
    },
    {
      img: safi10,
      url: "https://www.instagram.com/p/CiJoYfTOHYS/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      alt: "Mohammad Safi in the red square in Moscow",
    },
    {
      img: safi11,
      url: "https://www.instagram.com/p/CgM5nu0rP7e/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      alt: "Mohammad Safi in the streets of Latakia in Syria",
    },
    {
      img: safi12,
      url: "https://www.instagram.com/mohmmed_safi/",
      alt: "Mohammad Safi Training Boxing on punching bag",
    },
  ];
  return (
    <section className="flex items-center justify-center">
      <div className="text-center mt-12">
        <h2 className="text-2xl font-bold mb-4">Also the fun times</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          {instagramPosts.map((post, index) => (
            <a
              key={index}
              href={post.url}
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <img
                src={post.img}
                alt={post.alt}
                className="w-48 h-32 object-cover"
                loading="lazy"
              />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

function Story() {
  const ref = useRef();

  return (
    <div>
      <Helmet>
        <title>The Story of Mohammad Safi</title>
        <meta
          name="description"
          content="Discover the journey of Mohammad Safi."
        />

        {/* Open Graph Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mohammadsafi.com/story" />
        <meta property="og:title" content="The Story of Mohammad Safi" />
        <meta
          property="og:description"
          content="Discover the journey and experiences of Mohammad Safi."
        />
        <meta property="og:image" content={safi1} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://mohammadsafi.com/story" />
        <meta name="twitter:title" content="The Story of Mohammad Safi" />
        <meta
          name="twitter:description"
          content="Discover the journey and experiences of Mohammad Safi."
        />
        <meta name="twitter:image" content={safi1} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            mainEntity: [
              {
                "@type": "ImageObject",
                contentUrl: safi1,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi giving a presentation",
                name: "Mohammad Safi Public Speaking",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi2,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi in an interview",
                name: "Mohammad Safi Interview",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi3,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi with his professor",
                name: "Mohammad Safi with Professor",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi4,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description:
                  "Mohammad Safi standing in snow in front of Innopolis University",
                name: "Mohammad Safi at Innopolis University",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi5,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi at Innopolis University",
                name: "Mohammad Safi Innopolis",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi6,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi in his university",
                name: "Mohammad Safi University Life",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi7,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi sitting in a bar",
                name: "Mohammad Safi Bar",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi8,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi drinking in a library",
                name: "Mohammad Safi Library",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi9,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi standing in GUM mall for shopping",
                name: "Mohammad Safi GUM Mall",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi10,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi in the red square in Moscow",
                name: "Mohammad Safi Red Square Moscow",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi11,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi in the streets of Latakia in Syria",
                name: "Mohammad Safi Syria",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
              {
                "@type": "ImageObject",
                contentUrl: safi12,
                creator: { "@type": "Person", name: "Mohammad Safi" },
                description: "Mohammad Safi Training Boxing on punching bag",
                name: "Mohammad Safi Boxing",
                copyrightNotice: "© Safi",
                creditText: "Photo by Mohammad Safi",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="portfolio" ref={ref}>
        {items.map((item) => (
          <Single item={item} key={item.id} />
        ))}
        <StarsCanvas />
        <FunTimes />
      </div>
    </div>
  );
}

export default Story;
